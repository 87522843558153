import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import GlobalStyle, { IconInput } from '../../styles/global';
import * as styles from './styles'; // Importa todos os estilos de styles.js
import { Container, Content, ContentSection } from '../../styles/styles';
import { BackBar, BackButton } from '../../styles/global';
import { getUsers, deleteUser } from '../../controllers/userController';
import { SvgSearch, SvgDelete } from '../../assets/icons';
import { LuPenSquare } from "react-icons/lu";
import { FaChevronRight, FaPlus } from 'react-icons/fa';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]); // Estado para usuários filtrados
    const [searchTerm, setSearchTerm] = useState(''); // Estado para o termo de pesquisa
    const [errorMessage, setErrorMessage] = useState('');
    const userAuth = JSON.parse(localStorage.getItem('user')); // Usuário autenticado
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const userList = await getUsers();
                setUsers(userList);
                setFilteredUsers(userList); // Inicialmente, exibe todos os usuários
            } catch (error) {
                setErrorMessage('Erro ao buscar usuários.');
            }
        };

        fetchUsers();
    }, []);

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        const filtered = users.filter(user =>
            user.nome.toLowerCase().includes(term) ||
            user.cargo.toLowerCase().includes(term) ||
            user.unidade.toLowerCase().includes(term) ||
            user.email.toLowerCase().includes(term)
        );

        setFilteredUsers(filtered); // Atualiza os usuários filtrados
    };

    const handleEdit = (userId) => {
        navigate(`/perfil/editar/${userId}`);
    };

    const handleDelete = async (userId) => {
        if (window.confirm('Tem certeza de que deseja excluir este usuário?')) {
            try {
                await deleteUser(userId);
                setUsers(users.filter(user => user.idusuario !== userId)); // Remove o usuário da lista
                setFilteredUsers(filteredUsers.filter(user => user.idusuario !== userId)); // Remove o usuário filtrado
                alert('Usuário excluído com sucesso.');
            } catch (error) {
                alert('Erro ao excluir o usuário.');
                setErrorMessage('Erro ao excluir o usuário.');
            }
        }
    };

    return (
        <>
            <Header />
            <GlobalStyle />
            <Container fullWidth direction="column" align="center" justify="center">
                <Container direction="column" width="100%">
                    <BackBar>
                        <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                    </BackBar>

                    <ContentSection fullWidth>
                        <Content maxWidth="700px">
                            <Container justify="space-between" align="center" padding="0">
                                <h2>Lista de Usuários</h2>
                                <Link to="/perfil/novo">
                                    <button><FaPlus /> Novo usuário</button>
                                </Link>
                            </Container>

                            {errorMessage && <styles.ErrorMessage>{errorMessage}</styles.ErrorMessage>}

                            <IconInput>
                                <SvgSearch />
                                <input
                                    type="text"
                                    placeholder="Pesquisar usuários..."
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                            </IconInput>

                            <styles.UserListTable>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Cargo</th>
                                        <th>Unidade</th>
                                        <th>Email</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredUsers.map(user => (
                                        <tr key={user.idusuario}>
                                            <td>{user.nome}</td>
                                            <td>{user.cargo}</td>
                                            <td>{user.unidade}</td>
                                            <td>{user.email}</td>
                                            <td>
                                                <Container padding="0">
                                                    <button onClick={() => handleEdit(user.idusuario)}>
                                                        <LuPenSquare />
                                                    </button>
                                                    <button onClick={() => handleDelete(user.idusuario)}>
                                                        <SvgDelete />
                                                    </button>
                                                </Container>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </styles.UserListTable>
                        </Content>
                    </ContentSection>
                </Container>
            </Container>
        </>
    );
};

export default UserList;
