// Função para formatar data
export const formatDate = (datetime) => {
    const date = new Date(datetime);
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('pt-BR', options);
};

// Função para formatar hora
export const formatTime = (datetime) => {
    const date = new Date(datetime);
    const options = { hour: '2-digit', minute: '2-digit' };
    return date.toLocaleTimeString('pt-BR', options);
};

// Função para somar moedas recebidas
export const sumMoedas = (moedas) => {
    const moedasObj = JSON.parse(moedas); // Converte a string JSON para um objeto
    return Object.entries(moedasObj).reduce((total, [valor, quantidade]) => {
        return total + (parseInt(valor) * quantidade);
    }, 0);
};
