import React, { useState, useEffect } from 'react';
import GlobalStyle from "../styles/global";
import styled from 'styled-components';
import { IconInput } from '../styles/global';
import { PrimaryColor, TextColor, TextColorLight, Border } from "../styles/global";
import logo from '../assets/icons/logo-banco-da-felicidade.svg';
import { SvgKey, SvgUser } from '../assets/icons';
import { FaRegEnvelope } from "react-icons/fa";
import { Container } from '../styles/styles';
import Inputmask from 'inputmask';
import { recoverPassword, updatePassword } from '../controllers/userController'; 

const RecoverSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const RecoverContainer = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  color: ${TextColor};
  gap: 10px;

  h2 { margin-bottom: 20px; }

  img {
    margin-bottom: 20px;
    width: 150px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;

    input {
      padding: 5px 10px;
      border: 1px solid ${Border};
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      width: 100%;
    }

    button {
      padding: 5px 80px;
      background-color: ${PrimaryColor};
      color: ${TextColorLight};
      border: none;
      border-radius: 50px;
      cursor: pointer;
      font-size: 16px;
    }

    button:hover {
      background-color: ${PrimaryColor};
      opacity: 0.9;
    }
  }
`;

const FormContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 50px;
  margin-top: -40px;
`;

function RecoverPassword() {
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [step, setStep] = useState(1); // Controla o passo do formulário
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const removeMaskCPF = (cpf) => cpf.replace(/\D/g, '');

  const handleSubmitFirstStep = async (e) => {
    e.preventDefault();

    try {
      // Verifica se CPF e email correspondem ao usuário
      await recoverPassword(removeMaskCPF(cpf), email);
      setErrorMessage('');
      setStep(2); // Avança para o passo 2 (alterar senha)
    } catch (error) {
      setErrorMessage('CPF ou email inválido. Por favor, tente novamente.');
    }
  };

  const handleSubmitSecondStep = async (e) => {
    e.preventDefault();

    if (senha !== confirmarSenha) {
      setErrorMessage('As senhas não coincidem!');
      return;
    }

    try {
      // Atualiza a senha do usuário
      await updatePassword(removeMaskCPF(cpf), senha);
      setErrorMessage('');
      setSuccessMessage('Senha alterada com sucesso!');
      setTimeout(() => {
        window.location.href = '/login'; // Redireciona para login após sucesso
      }, 5000);
    } catch (error) {
      setErrorMessage('Erro ao alterar a senha. Tente novamente.');
    }
  };

  return (
    <>
      <GlobalStyle />
      <RecoverSection>
        <RecoverContainer>
          <img src={logo} alt="Logo Banco da Felicidade" />
          <h2>Recuperação de Senha</h2>
          
          {step === 1 ? (
            <>
              <p>Insira seu CPF e email para verificar seus dados.</p>
              <form onSubmit={handleSubmitFirstStep}>
                <IconInput>
                  <SvgUser />
                  <input
                    type="text"
                    id="cpf"
                    name="cpf"
                    placeholder="CPF"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                    required
                  />
                </IconInput>
                <IconInput>
                  <FaRegEnvelope />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </IconInput>
                <FormContainer direction="column">
                  <button type="submit">Verificar</button>
                </FormContainer>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              </form>
            </>
          ) : (
            <>
              <p>Agora insira sua nova senha.</p>
              <form onSubmit={handleSubmitSecondStep}>
                <IconInput>
                  <SvgKey />
                  <input
                    type="password"
                    id="senha"
                    name="senha"
                    placeholder="Nova Senha"
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                    required
                  />
                </IconInput>
                <IconInput>
                  <SvgKey />
                  <input
                    type="password"
                    id="confirmarSenha"
                    name="confirmarSenha"
                    placeholder="Confirmar Senha"
                    value={confirmarSenha}
                    onChange={(e) => setConfirmarSenha(e.target.value)}
                    required
                  />
                </IconInput>
                <FormContainer direction="column">
                  <button type="submit">Alterar Senha</button>
                </FormContainer>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
              </form>
            </>
          )}
        </RecoverContainer>
      </RecoverSection>
    </>
  );
}

export default RecoverPassword;
