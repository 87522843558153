// components/PremioFooter.js
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, BackgroundColor, BackgroundColorLight, Border, Accent } from "../../styles/global";
import { Link } from 'react-router-dom';
import { logout, isAuthenticated } from '../../auth';
import { deletePremio } from '../../controllers/premioController';


const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  padding: 10px 20px;
  background-color: ${SecondaryColor};
  color: ${TextColorLight};
  position: fixed;
  bottom: 0;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
`;

const FooterButton = styled.button`
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${props => props.danger ? 'red' : Accent};
  color: ${props => props.danger ? TextColorLight : TextColor};

  &:hover {
    background-color: ${PrimaryColor};
    color: ${TextColorLight};
  }
`;

const PremioFooter = ({ idpremio }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user')); // Obter usuário logado

  if (user.nivel !== 1) {
    return null; // Não exibir o rodapé se o nível do usuário não for 1
  }

  const handleCreate = () => {
    navigate('/premios/premio/novo');
  };

  const handleEdit = () => {
    if (idpremio) {
      navigate(`/premios/premio/editar/${idpremio}`);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Tem certeza de que deseja excluir este prêmio?')) {
      try {
        await deletePremio(idpremio);
        navigate('/premios');
      } catch (error) {
        alert('Erro ao excluir o prêmio: ' + error.message);
      }
    }
  };

  return (
    <FooterContainer>
      <FooterButton onClick={handleCreate}>Criar Novo Prêmio</FooterButton>
      {idpremio && (
        <>
          <FooterButton onClick={handleEdit}>Editar Prêmio</FooterButton>
          <FooterButton danger onClick={handleDelete}>Excluir Prêmio</FooterButton>
        </>
      )}
    </FooterContainer>
  );
};

export default PremioFooter;
