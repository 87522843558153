import React, { useState } from 'react';
import styled from 'styled-components';
import { Container } from '../styles/styles';
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, BackgroundColor, BackgroundColorLight ,Accent } from '../styles/global';
import { BackBar, BackButton } from '../styles/global';
import { ContentSection, Content } from '../styles/styles';

import { SvgUser } from '../assets/icons';
import coin1 from '../assets/icons/moeda-1.svg';
import coin5 from '../assets/icons/moeda-5.svg';
import coin10 from '../assets/icons/moeda-10.svg';
import coin50 from '../assets/icons/moeda-50.svg';
import coin100 from '../assets/icons/moeda-100.svg';

const Employee = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${BackgroundColor};
  width: fit-content;
  padding: 10px 12px;
  gap: 30px;

  .employee-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    text-align: left;
  }

  .employee-details {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0px;
    flex: 1;
    text-align: left;
    p, small{font-size: 12px;width: max-content;}

    p{font-weight: 500;}
    small{font-weight: lighter;}
  }

  .employee-icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: ${PrimaryColor};
    text-align: center;
    padding: 10px;
  }
  .employee-icon {
    font-size: 10px;
    color: ${TextColorLight};
  }
`;

const CoinSelection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const CoinItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  padding: 10px 20px;
  border-radius: 20px;

  img {
    width: 70px;
    height: 70px;
    flex: 1;
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
  }

  div span{
    font-size: 23px;
    font-weight: 600;
    background: ${SecondaryColor};
    color: ${TextColorLight};
    padding: 5px;
    border-radius: 10px;
  }
`;

const CoinButton = styled.button`
  background: none;
  color: ${SecondaryColor};
  border: none;
  padding: 10px;
  font-size: 35px;
  cursor: pointer;

  &:hover {
    background: none!important;
  }
`;

const Amount = styled.span`
  font-size: 40px;
  font-weight: 600;
  color: ${TextColor};
`;

const ContinueButton = styled.button`
  background-color: ${PrimaryColor};
  color: ${TextColorLight};
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

function SelecionarMoedas({ emocionador, onConfirm, onBack, onClose, errorMessage }) {
  const [selectedMoedas, setSelectedMoedas] = useState({ 1: 0, 5: 0, 10: 0, 50: 0, 100: 0 });

  const handleMoedaChange = (value, amount) => {
    setSelectedMoedas({
      ...selectedMoedas,
      [value]: Math.max(0, selectedMoedas[value] + amount), // previne valor negativo
    });
  };

  const totalMoedas = Object.keys(selectedMoedas).reduce((total, value) => {
    return total + selectedMoedas[value] * parseInt(value);
  }, 0);

  const canContinue = Object.values(selectedMoedas).some(amount => amount > 0);

  return (
    <Container direction="column" width="100%">
      <BackBar>
        <BackButton onClick={onBack}>← Voltar</BackButton>
        <BackButton onClick={onClose}>X</BackButton>
      </BackBar>

      <ContentSection fullWidth>
        <Content bgColor={Accent} align="center" gap="30px" padding="40px 10px">
          <Employee shadow>
            <div className='employee-name'>
              <div className="employee-icon-div">
                <SvgUser className="employee-icon" />
              </div>
              <h4>{emocionador.nome}</h4>
            </div>
            <div className="employee-details">
              <p>{emocionador.unidade}</p>
              <small>{emocionador.setor}</small>
            </div>
          </Employee>
          <CoinSelection>

            <Amount>{totalMoedas}</Amount>

            {Object.entries(selectedMoedas).map(([value, amount]) => (
              <CoinItem key={value}>
                <img src={
                  value === '1' ? coin1 :
                  value === '5' ? coin5 :
                  value === '10' ? coin10 :
                  value === '50' ? coin50 :
                  coin100
                } alt={`${value} Moedas`} />
                <div>
                  <CoinButton onClick={() => handleMoedaChange(value, -1)}>-</CoinButton>
                  <span>{amount}</span>
                  <CoinButton onClick={() => handleMoedaChange(value, +1)}>+</CoinButton>
                </div>
              </CoinItem>
            ))}
          </CoinSelection>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <ContinueButton onClick={() => onConfirm(selectedMoedas)} disabled={!canContinue}>Continue</ContinueButton>
        </Content>
      </ContentSection>
    </Container>
  );
}

export default SelecionarMoedas;
