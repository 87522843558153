import { API_URL } from './api.js';

// Função para buscar emocionadores recentes
export const getEmocionadoresRecentes = async (usuarioId) => {
    try {
        const response = await fetch(`${API_URL}/emocionadores-recentes/${usuarioId}`);
        if (!response.ok) {
            throw new Error('Erro ao buscar emocionadores recentes');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro ao buscar emocionadores recentes:', error.message);
        throw error;
    }
};

// Função para buscar todos os emocionadores com nível inferior ao usuário logado
export const getEmocionadores = async (usuarioId) => {
    try {
        const response = await fetch(`${API_URL}/emocionadores/${usuarioId}`);
        if (!response.ok) {
            throw new Error('Erro ao buscar emocionadores');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro ao buscar emocionadores:', error.message);
        throw error;
    }
};
