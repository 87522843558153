import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Content, ContentSection } from '../styles/styles';
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, TextColorDark, BackgroundColor, BackgroundColorLight, Accent } from '../styles/global';
import { BackBar, BackButton } from '../styles/global';
import { SvgUser, SvgCalendar, SvgUpload } from '../assets/icons';

const JustificationForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: ${SecondaryColor};
  border-radius: 10px;
  align-items: center;
`;

const Employee = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  background-color: ${BackgroundColor};
  width: fit-content;
  padding: 10px 12px;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;

  p, h5 { color: ${TextColor}; }
  p, small { font-size: 12px; }
  p { font-weight: 500; }
  small { font-weight: lighter; }

  .employee-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    text-align: left;
  }

  .employee-details {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0px;
    flex: 1;
    text-align: left;
    small { width: max-content; }
  }

  .employee-icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: ${SecondaryColor};
    text-align: center;
    padding: 10px;
  }
  
  .employee-icon {
    font-size: 10px;
    color: ${TextColorLight};
  }
`;

const SelectReason = styled(Container)`
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background-color: ${TextColorLight};
  

  p{
    color: ${TextColorDark};
    padding: 0 5px;
    font-size: 12px;
  }

    select{
      border: none;
      color: ${TextColor};
      font-weight: 600;
      &:focus-visible{
        outline: 0px;
      }
    }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  min-height: 130px;
  color: ${TextColor};

  &::placeholder {
    color: ${TextColor};
  }
`;

const DateInput = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${TextColorLight};
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  justify-content: space-between;

  input {
    display: none;
  }

  svg {
    width: 30px;
    color: ${PrimaryColor};
  }
  
  div {
    display: flex;
    justify-content: start;
    align-items: center;
    width: fit-content;
    gap: 10px;
  }
`;

const FileInput = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  background-color: ${TextColorLight};
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  

  input {
    display: none;
  }

  svg {
    width: 45px;
    color: ${PrimaryColor};
  }

  img{
    width: 60%;
    border-radius: 8px;
  }

  .interna {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .interna div {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: fit-content;
    gap: 10px;
  }
`;

const SubmitButton = styled.button`
  background-color: ${BackgroundColor};
  color: ${TextColor};
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background-color: ${PrimaryColor};
    color: ${TextColorLight};
  }
`;

const RequiredText = styled.p`
  font-size: 12px;
  color: ${TextColorLight}!important;
  margin-top: -15px;
  align-self: flex-start;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

const JustificarEmocao = ({ emocionador, moedas, onSubmit, onBack, onClose, errorMessage }) => {
  const [justification, setJustification] = useState('');
  const [selectedReason, setSelectedReason] = useState('');
  const [displayDate, setDisplayDate] = useState('');
  const [isoDate, setIsoDate] = useState('');
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    const currentDate = new Date();
    setDisplayDate(currentDate.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }));
    setIsoDate(currentDate.toISOString());
  }, []);

  const handleChange = (e) => setJustification(e.target.value);

  const handleReasonChange = (e) => setSelectedReason(e.target.value);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setPreviewImage(URL.createObjectURL(file)); // Mostra a imagem selecionada
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      justification,
      reason: selectedReason,
      date: isoDate,
      file,
    });
  };

  const reasonTexts = {
    "Rir": "Quem ri mais vive mais. Torne nosso atendimento caloroso.",
    "Cumprimente sempre": "Cumprimente o hóspede com um sorriso, um gesto Tauá e palavras calorosas.",
    "Converse com o hóspede": "Aproxime-se e pergunte se ele precisa de algo para se sentir em casa.",
    "O cliente é o rei": "Pare tudo o que está fazendo. Dê atenção ao hóspede: resolva o problema, carregue a mala, acompanhe-o até onde ele quer ir, pegue o que ele precisa.",
    "Seja colaborativo": "Ajude os colegas e outros setores. Pegue o papel do chão: todos somos responsáveis pela limpeza.",
    "Atenção total, cara alegre e rápido": "Trabalhe com excelência e atenção aos detalhes. Mantenha o foco e evite o uso do celular durante o trabalho.",
    "Tauá é uma empresa de oportunidades": "Capacite-se e se esforce. Queremos e incentivamos o crescimento interno.",
    "Cante a música 'Amigos para sempre'": "Cante alto, com entusiasmo e com um sorriso no rosto.",
    "Trabalhe em prol do resultado": "Acompanhe suas metas e seja ético em suas atitudes e tarefas.",
    "Seja feliz": "Fazer alguém feliz também nos faz felizes. Tenha humildade, simplicidade, respeito e gratidão."
  };

  return (
    <Container direction="column" width="100%">
      <BackBar>
        <BackButton onClick={onBack}>← Voltar</BackButton>
        <BackButton onClick={onClose}>X</BackButton>
      </BackBar>

      <ContentSection fullWidth>
        <Content>
          <JustificationForm onSubmit={handleSubmit}>
            <Employee shadow>
              <div className='employee-name'>
                <div className="employee-icon-div">
                  <SvgUser className="employee-icon" />
                </div>
                <h5>{emocionador.nome}</h5>
              </div>
              <small>{emocionador.email}</small>
              <div className="employee-details">
                <p>{emocionador.unidade}</p>
                <small>{emocionador.setor}</small>
              </div>
            </Employee>

            <Container direction="column" width="80%" gap="20px">
              <SelectReason >
                <select value={selectedReason} onChange={handleReasonChange} required>
                  <option value="">Selecione uma razão</option>
                  <option value="Rir">Rir</option>
                  <option value="Cumprimente sempre">Cumprimente sempre
                  </option>
                  <option value="Converse com o hóspede">Converse com o hóspede</option>
                  <option value="O cliente é o rei">O cliente é o rei</option>
                  <option value="Seja colaborativo">Seja colaborativo</option>
                  <option value="Atenção total, cara alegre e rápido">Atenção total, cara alegre e rápido</option>
                  <option value="Tauá é uma empresa de oportunidades">Tauá é uma empresa de oportunidades</option>
                  <option value="Cante a música 'Amigos para sempre'">Cante a música 'Amigos para sempre'</option>
                  <option value="Trabalhe em prol do resultado">Trabalhe em prol do resultado</option>
                  <option value="Seja feliz">Seja feliz</option>
                </select>

                {selectedReason && (
                  <p>{reasonTexts[selectedReason]}</p>
                )}

              </SelectReason>
              <RequiredText>Obrigatório *</RequiredText>



              <TextArea
                value={justification}
                onChange={handleChange}
                placeholder="Descrição da emoção"
                required
              />
              <RequiredText>Obrigatório *</RequiredText>

              <DateInput>
                <div>
                  <p><strong>Emocionou em</strong></p>
                </div>
                <div>
                  <SvgCalendar />
                  <span>{displayDate}</span>
                  <input type="hidden" value={isoDate} />
                </div>
              </DateInput>
              <RequiredText>Obrigatório *</RequiredText>

              <FileInput>
              <div className='interna'>
                <div>
                  <p><strong>Foto da entrega </strong></p>
                </div>
                <div>
                  <SvgUpload />
                  <span>Enviar imagem</span>
                  <input type="file" onChange={handleFileChange} accept="image/*" />
                </div>
                </div>
                {previewImage && <img src={previewImage} alt="Pré-visualização da imagem" />}
              </FileInput>
            </Container>

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

            <SubmitButton type="submit">Confirmar</SubmitButton>
          </JustificationForm>
        </Content>
      </ContentSection>
    </Container>
  );
};

export default JustificarEmocao;
