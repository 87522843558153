import '../fonts.css';
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

// Theme Colors
const PrimaryColor = '#EC6726';
const SecondaryColor = '#FF9500';
const TextColor = '#EC6726';
const TextColorLight = '#FFFFFF';
const TextColorDark = '#585858';
const BackgroundColor = '#FFFFFF';
const BackgroundColorLight = '#EC6726';
const Border = '#e1e1e1';
const Accent = '#ffeacc';

// Layout Constants
const ContentWidth = '1140px';

const GlobalStyle = createGlobalStyle`
    
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Co Headline', sans-serif;
        font-weight: normal;
        width: 100%;
        //color: ${TextColor};
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Co Headline', sans-serif;
        font-weight: bold;
        color: ${TextColor};
    }

    p, span{
        color: ${TextColor};
        font-weight: lighter;
        width: inherit;
    }


    a {
        text-decoration: none;
        color: unset;
        width: inherit;
    }

    body {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        background-color: ${BackgroundColor};
    }

    button {
        display: flex;
        align-items: center;
        gap: 5px;
        background-color: ${PrimaryColor};
        color: ${TextColorLight};
        border: none;
        padding: 2px 8px;
        border-radius: 5px;
        cursor: pointer;
        width: fit-content;
        transition: 0.5s;

        svg {width:auto;}
    }

    button:hover {
        background-color: ${SecondaryColor};
    }

    input, textarea, select {
    font-size: 15px;
    font-weight: 300;
    }
    
`;

const BackBar = styled.div`
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  max-width: 100%;
`;

const BackButton = styled.a`
  display: flex;
  align-items: center;
  color: ${PrimaryColor};
  text-decoration: none;
  font-size: 22px;
  margin-bottom: 20px;
  width: fit-content;
  cursor: pointer;

  :hover {
    color: ${SecondaryColor};
  }
`;

const IconInput = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;           
  border: none;                         
  padding: 5px 15px;
  border-radius: 20px;
  margin-bottom: 20px;
  background: ${Accent};
  align-items: center;
  gap: 10px;

    input {
        border: none!important;;
        background: none;
        color: ${TextColorDark};

        &::placeholder {
            color: ${TextColorDark};
        }
        &:focus-visible {
            outline: none;
        }
    }

    svg {color: ${PrimaryColor};width: 22px;}
`;

export default GlobalStyle;
export { PrimaryColor, SecondaryColor, TextColor, TextColorLight, TextColorDark, BackgroundColor, BackgroundColorLight, Accent, Border };
export { ContentWidth };
export { BackBar, BackButton, IconInput };