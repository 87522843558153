import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import Success, { InfoContainer, InfoContainer50, InfoContainer100, InfoContainer33, CloseButton } from '../../components/success';
import placeholderImage from '../../assets/images/placeholder.png';
import Header from '../../components/header';
import GlobalStyle from '../../styles/global';
import { Container } from '../../styles/styles';
import { API_URL } from '../../controllers/api';


const PremioResgatadoSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { premio } = location.state || {}; // Acessa o `premio` do `state`  
    const { quantity } = location.state || {}; // Acessa a `quantidade` do `state`
    const user = JSON.parse(localStorage.getItem('user'));
    
    const content = (
        <>
            <h3>Prêmio resgatado com sucesso!</h3>
            <InfoContainer light wrap="wrap" gap="0">
                <InfoContainer33>
                    <p><strong>Emocionador</strong></p><p>{user.nome}</p>
                </InfoContainer33>
                <InfoContainer33>
                    <p><strong>Unidade</strong></p><p>{user.unidade}</p>
                </InfoContainer33>
                <InfoContainer33>
                    <p><strong>Seção</strong></p><p>{user.setor}</p>
                </InfoContainer33>
                <InfoContainer33>
                    <p><strong>Entrega</strong></p><p>{user.unidade}</p>
                </InfoContainer33>
                <InfoContainer33>
                    <p><strong>Valor</strong></p><p>{premio.valor}</p>
                </InfoContainer33>
                <InfoContainer33>
                    <p><strong>Quantidade</strong></p><p>{quantity}</p>
                </InfoContainer33>
                <InfoContainer100>
                    <p><strong>Prêmio</strong></p>
                    <Container gap="20px">
                    <img src={API_URL + premio.url_imagem || placeholderImage} alt={premio.nome} />
                    <Container light padding="0" direction="column">
                    <h4>{premio.nome}</h4>
                    <p><strong>{premio.valor} x {quantity}</strong></p>
                    </Container>
                    </Container>
                </InfoContainer100>
            </InfoContainer>
        </>
    );

    return (
        <>
            <Header />
            <GlobalStyle />
            <Container fullWidth direction="column" align="center" justify="center">
                <Success content={content} onClose={() =>navigate('/')} />
            </Container>
        </>

    );
};

export default PremioResgatadoSuccess;
