import axios from 'axios';

import { API_URL } from './api.js';

export const getUserData = async (userId) => {
    try {
        const response = await axios.get(`${API_URL}/user/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
        throw error;
    }
};

// Função para buscar todos os usuários
export const getUsers = async () => {
    try {
        const response = await axios.get(`${API_URL}/user`);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { mensagem: 'Erro ao buscar usuários.' };
    }
};

export const createUser = async (formData) => {
    try {
        const response = await axios.post(`${API_URL}/user`, formData);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { mensagem: 'Erro ao se comunicar com o servidor' };
    }
};

export const updateUser = async (userId, authData, formData) => {
    try {
        const response = await axios.put(`${API_URL}/user/${userId}`, { ...authData, ...formData });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { mensagem: 'Erro ao se comunicar com o servidor' };
    }
};

// Função para deletar um usuário pelo ID
export const deleteUser = async (userId) => {
    try {
        const response = await axios.delete(`${API_URL}/user/${userId}`);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { mensagem: 'Erro ao excluir o usuário.' };
    }
};

// Função para verificar o CPF e o email do usuário
export const recoverPassword = async (cpf, email) => {
    try {
        const response = await axios.post(`${API_URL}/recover-password`, { cpf, email });
        return response.data; // Retorna a mensagem de verificação bem-sucedida
    } catch (error) {
        throw error.response ? error.response.data : { mensagem: 'Erro ao se comunicar com o servidor' };
    }
};

// Função para alterar a senha do usuário
export const updatePassword = async (cpf, senha) => {
    try {
        const response = await axios.put(`${API_URL}/update-password`, { cpf, senha });
        return response.data; // Retorna a mensagem de sucesso da alteração de senha
    } catch (error) {
        throw error.response ? error.response.data : { mensagem: 'Erro ao se comunicar com o servidor' };
    }
};


export const loginUser = async (cpf, senha) => {
    try {
        const response = await axios.post(`${API_URL}/login`, { cpf, senha });
        return response.data; // Retorna os dados do usuário em caso de sucesso
    } catch (error) {
        throw error.response ? error.response.data : { mensagem: 'Erro ao se comunicar com o servidor' };
    }
};

export const getUserHistory = async (userId) => {
    try {
        const response = await axios.get(`${API_URL}/user/${userId}/history`);
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar histórico do usuário:', error);
        throw error;
    }
};