import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from './auth';

import Home from './pages/home';
import Login from './pages/login';
import RecoverPassword from './pages/recuperar-senha';

import Emocionar from './pages/emocionar';
import DistribuirMoedas from './pages/distribuir-moedas';
import CriarPremio from './pages/premio/add';
import EditarPremio from './pages/premio/edit';
import PremioList from './pages/premio/';
import PremioDetails from './pages/premio/view';
import PremioResgatadoSuccess from './pages/premio/neworder';
import ProtectedRoute from './protectedRoute';  // Importar o componente ProtectedRoute
import UserDetails from './pages/user/';
import UserEdit from './pages/user/edit';
import UserCreate from './pages/user/add';
import UserList from './pages/user/view';

function AppRoutes() {
  return (
    <BrowserRouter>
        <Routes>

          {/* Rota Home, só acessível se o usuário estiver autenticado */}
            <Route path="/" element={
                !isAuthenticated() ? <Navigate to="/login" replace /> : <Home />
            } />

            {/* Rota de login, só acessível se o usuário não estiver autenticado */}
            <Route path="/login" element={
                isAuthenticated() ? <Navigate to="/" replace /> : <Login />
            } />

            <Route path="/recuperar-senha" element={<RecoverPassword />} />

            {/* Rotas protegidas */}
            <Route path="/emocionar" element={<ProtectedRoute element={Emocionar} />} />
            <Route path="/distribuir-moedas" element={<ProtectedRoute element={DistribuirMoedas} />} />
            <Route path="/premios" element={<ProtectedRoute element={PremioList} />} />
            <Route path="/premios/premio/novo" element={<ProtectedRoute element={CriarPremio} levelRequired={1} />} />
            <Route path="/premios/premio/editar/:id" element={<ProtectedRoute element={EditarPremio} levelRequired={1} />} />
            <Route path="/premios/premio/:id" element={<ProtectedRoute element={PremioDetails} />} />
            <Route path="/resgate-concluido" element={<ProtectedRoute element={PremioResgatadoSuccess} />} />
            <Route path="/perfil" element={<ProtectedRoute element={UserDetails} />} />
            <Route path="/perfil/editar/:id" element={<ProtectedRoute element={UserEdit} />} />
            <Route path="/perfil/novo" element={<ProtectedRoute element={UserCreate} levelRequired={1}/>} />
            <Route path="/usuarios" element={<ProtectedRoute element={UserList} levelRequired={1} />} />

        </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
