import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import GlobalStyle from '../../styles/global';
import * as styles from './styles';
import { Container, Content, ContentSection } from '../../styles/styles';
import { BackBar, BackButton } from '../../styles/global';
import { SvgUser } from '../../assets/icons';
import { getUserData, updateUser } from '../../controllers/userController';
import { Employee, Form, InputField, SelectField, RequiredText, ErrorMessage, SubmitButton, Aviso } from './styles';

const UserEdit = () => {
    const user = JSON.parse(localStorage.getItem('user')); // Usuário autenticado
    const { id } = useParams(); // ID do usuário passado por parâmetro
    const navigate = useNavigate();

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [cpf, setCpf] = useState('');
    const [cargo, setCargo] = useState('');
    const [unidade, setUnidade] = useState('');
    const [secao, setSecao] = useState('');
    const [setor, setSetor] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [notFound, setNotFound] = useState(false);
    const isEditingOwnAccount = user.idusuario === parseInt(id, 10); // Verifica se está editando o próprio usuário

    useEffect(() => {
        // Verificação: Se o usuário não for nível 1 e não estiver editando sua própria conta, redireciona
        if (user.nivel !== 1 && !isEditingOwnAccount) {
            alert('Você não tem permissão para editar este usuário.');
            navigate('/'); // Redireciona para a página inicial ou outra página de sua escolha
        }

        if (id) {
            getUserData(id)
                .then(data => {
                    if (!data) {
                        setNotFound(true);
                    } else {
                        setNome(data.nome);
                        setEmail(data.email);
                        setCpf(data.cpf);
                        setCargo(data.cargo);
                        setUnidade(data.unidade);
                        setSecao(data.secao);
                        setSetor(data.setor);
                    }
                })
                .catch(err => setErrorMessage(err.message) && setNotFound(true));
        }
    }, [id, user.nivel, isEditingOwnAccount, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isEditingOwnAccount && senha !== confirmarSenha) {
            setErrorMessage('As senhas não coincidem!');
            return;
        }

        let formData = {
            nome,
            email,
            cpf,
            cargo,
            unidade,
            secao,
            setor,
        };

        // Criptografar a senha apenas se o usuário estiver editando sua própria conta
        if (isEditingOwnAccount && senha) {
            formData.senha = senha;
        }

        try {
            await updateUser(id, { nivelAuth: user.nivel, idAuth: user.idusuario }, formData);
            alert('Usuário atualizado com sucesso!');
            navigate(-1);
        } catch (error) {
            setErrorMessage('Erro ao atualizar o usuário: ' + error.mensagem);
        }
    };

    if (notFound) {
        return (
            <>
                <Header />
                <GlobalStyle />
                <ContentSection fullWidth height="100vh">
                    <Content direction="column" align="center">
                        <Aviso light>
                            <p>Úsuario não encontrado</p>
                            <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                        </Aviso>
                    </Content>
                </ContentSection>
            </>
        );
    }

    return (
        <>
            <Header />
            <GlobalStyle />
            <Container fullWidth direction="column" align="center" justify="center">
                <Container direction="column" width="100%">
                    <BackBar>
                        <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                        <BackButton onClick={() => navigate('/')}>X</BackButton>
                    </BackBar>

                    <ContentSection fullWidth>
                        <Content light>
                            <Employee light>
                                <div className="employee-icon-div">
                                    <SvgUser className="employee-icon" />
                                </div>
                                <div className="employee-details">
                                    <h3>{nome} - <small>{unidade}</small></h3>
                                    <small>{email}</small>
                                    <small>{cargo} - {secao} - {setor}</small>
                                </div>
                            </Employee>
                            <Form onSubmit={handleSubmit}>
                                <h2>Editar Usuário</h2>
                                <Container direction="column" width="80%" gap="15px">

                                    <InputField
                                        type="text"
                                        placeholder="CPF"
                                        value={cpf}
                                        disabled
                                    />
                                    <RequiredText>Obrigatório *</RequiredText>

                                    <InputField
                                        type="email"
                                        placeholder="Email do usuário"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        required
                                    />
                                    <RequiredText>Obrigatório *</RequiredText>

                                    {isEditingOwnAccount && (
                                        <>
                                            <InputField
                                                type="password"
                                                placeholder="Nova Senha"
                                                value={senha}
                                                onChange={e => setSenha(e.target.value)}
                                            />
                                            <RequiredText>Obrigatório *</RequiredText>

                                            <InputField
                                                type="password"
                                                placeholder="Confirmar Senha"
                                                value={confirmarSenha}
                                                onChange={e => setConfirmarSenha(e.target.value)}
                                            />
                                            <RequiredText>Obrigatório *</RequiredText>
                                        </>
                                    )}

                                    {user.nivel == 1 && (
                                        <>
                                            <InputField
                                                type="text"
                                                placeholder="Nome do usuário"
                                                value={nome}
                                                onChange={e => setNome(e.target.value)}
                                                required
                                            />
                                            <RequiredText>Obrigatório *</RequiredText>

                                            <SelectField value={cargo} onChange={e => setCargo(e.target.value)}>
                                                <option value="">Selecione o Cargo</option>
                                                <option value="Membro da Diretoria">Membro da Diretoria</option>
                                                <option value="Gerente Geral">Gerente Geral</option>
                                                <option value="Gerente Operacional">Gerente Operacional</option>
                                                <option value="Gerente Corporativo">Gerente Corporativo</option>
                                                <option value="Gestor">Gestor</option>
                                                <option value="Coordenador Corporativo">Coordenador Corporativo</option>
                                                <option value="Coordenador">Coordenador</option>
                                                <option value="Supervisor">Supervisor</option>
                                                <option value="Colaborador">Colaborador</option>
                                            </SelectField>
                                            <RequiredText>Obrigatório *</RequiredText>

                                            <SelectField value={unidade} onChange={e => setUnidade(e.target.value)}>
                                                <option value="">Selecione a Unidade</option>
                                                <option value="Holding">Holding</option>
                                                <option value="Alegro">Alegro</option>
                                                <option value="Alexânia">Alexânia</option>
                                                <option value="Araxá">Araxá</option>
                                                <option value="Atibaia">Atibaia</option>
                                                <option value="Caeté">Caeté</option>
                                            </SelectField>
                                            <RequiredText>Obrigatório *</RequiredText>

                                            <InputField
                                                type="text"
                                                placeholder="Seção"
                                                value={secao}
                                                onChange={e => setSecao(e.target.value)}
                                            />
                                            <RequiredText>Obrigatório *</RequiredText>

                                            <InputField
                                                type="text"
                                                placeholder="Setor"
                                                value={setor}
                                                onChange={e => setSetor(e.target.value)}
                                            />
                                            <RequiredText>Obrigatório *</RequiredText>
                                        </>
                                    )}

                                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

                                    <SubmitButton type="submit">
                                        Confirmar
                                    </SubmitButton>
                                </Container>
                            </Form>
                        </Content>
                    </ContentSection>
                </Container>
            </Container>
        </>
    );
};

export default UserEdit;
