import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import GlobalStyle, { IconInput } from '../../styles/global';
import { ErrorMessage, PremiosGrid, PremioItem, Pagination } from './styles';
import placeholderImage from '../../assets/images/placeholder.png';
import { SvgSearch } from '../../assets/icons';
import { BackBar, BackButton } from '../../styles/global';
import { Container, Content, ContentSection } from '../../styles/styles';
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, TextColorDark ,Accent } from '../../styles/global';
import { getPremios } from '../../controllers/premioController';
import { API_URL } from '../../controllers/api';
import PremioFooter from './premio-adm-footer';

const user = JSON.parse(localStorage.getItem('user'));

const PremioList = () => {
    const [premios, setPremios] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {

        getPremios()
            .then(setPremios)
            .catch(() => setErrorMessage('Erro ao carregar os prêmios.'));

    }, []);

    const filteredPremios = premios.filter(premio =>
        premio.nome.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const premiosPerPage = 9; // Número de prêmios por página
    const startIndex = (currentPage - 1) * premiosPerPage;
    const selectedPremios = filteredPremios.slice(startIndex, startIndex + premiosPerPage);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reinicia para a primeira página ao realizar uma busca
    };

    const handleNextPage = () => {
        if (startIndex + premiosPerPage < filteredPremios.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Verifica se há erro ou nenhum prêmio
    if (errorMessage || premios.length === 0) {
        return (
            <>
                <Header />
                <GlobalStyle />
                <ContentSection fullWidth height="100vh">
                    <Content direction="column" align="center">
                        <ErrorMessage>{errorMessage || 'Nenhum prêmio encontrado.'}</ErrorMessage>
                        <BackButton onClick={() => navigate('/')}>← Voltar</BackButton>
                    </Content>
                </ContentSection>
            </>
        );
    }

    return (
        <>
            <Header />
            <GlobalStyle />
            <Container fullWidth direction="column" align="center" justify="center">
                <ContentSection fullWidth>
                    <Content>
                        
                        <IconInput>
                            <SvgSearch />
                            <input
                                type="text"
                                placeholder="Pesquisa de prêmios"
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </IconInput>

                        <PremiosGrid>
                            {selectedPremios.map(premio => (
                                <PremioItem key={premio.idpremio} onClick={() => navigate(`/premios/premio/${premio.idpremio}`)}>
                                    <img src={ API_URL + premio.url_imagem || placeholderImage} alt={premio.nome} />
                                    <h5>{premio.nome}</h5>
                                    <p>Valor <strong>{premio.valor}</strong></p>
                                </PremioItem>
                            ))}
                        </PremiosGrid>
                        <Pagination>
                            <button onClick={handlePreviousPage}>← Página anterior</button>
                            <button onClick={handleNextPage}>Página seguinte →</button>
                        </Pagination>
                    </Content>
                </ContentSection>
            </Container>

            {user.nivel == 1 ? (<PremioFooter />) : null}
        </>
    );
};

export default PremioList;
