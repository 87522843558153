import styled from "styled-components";
import { Container } from "../../styles/styles";
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, TextColorDark ,BackgroundColor, BackgroundColorLight, Border, Accent } from "../../styles/global";
import breakpoints from "../../styles/breakpoints";

export const PremioForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: ${SecondaryColor};
  border-radius: 10px;
  align-items: center;

  h2{color: ${TextColorLight};}
`;

export const InputField = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background-color: ${TextColorLight};
  color: ${TextColor};

  &::placeholder {
    color: ${TextColor};
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  min-height: 130px;
  color: ${TextColor};

  &::placeholder {
    color: ${TextColor};
  }
`;

export const FileInput = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: ${TextColorLight};
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  justify-content: space-between;

  input {
    display: none;
  }

  img {
    width: 60%;
  }

  p {
    color: ${TextColor};
  }

  svg {
    width: 45px;
    color: ${PrimaryColor};
  }

  .interna {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .interna div {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: fit-content;
    gap: 10px;
    padding: 10px 0;
  }
`;

export const DeleteButton = styled.button`
  background-color: ${BackgroundColor};
  color: ${TextColor};
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: top;
  justify-content: center;

  &:hover {
    background-color: ${Accent};
  }

  svg {
    width: 25px;
    height: 25px;
  }
`;

export const SubmitButton = styled.button`
  background-color: ${PrimaryColor};
  color: ${TextColorLight};
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background-color: ${BackgroundColor};
    color: ${TextColor};
  }
`;

export const RequiredText = styled.p`
  font-size: 12px;
  color: ${TextColorLight}!important;
  margin-top: -15px;
  align-self: flex-start;
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

export const Aviso = styled(Container)`
    background-color: ${BackgroundColorLight};
    p, a{color: ${TextColorLight};}
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 8px;
    font-size: 14px;
`;

// Index.js

export const PremiosGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
`;

export const PremioItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
    background: #FFFDFB;
    color: ${TextColorDark};
    padding: 20px;
    border: 2px solid ${Border};
    border-radius: 20px;
    cursor: pointer;
    transition: 0.5s;

    img {
        width: 100%;
        max-height: 150px;
        object-fit: cover;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    h5, p {color: ${TextColorDark};}

    p {
        font-size: 10px;
    }

    &:hover {
        border: 2px solid ${PrimaryColor};
        background-color: #FFFFFF;
    }
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
        background: none;
        border: none;
        color: ${PrimaryColor};
        cursor: pointer;
        font-size: 16px;

        &:hover {
            text-decoration: underline;
        }
    }
`;


// View


export const PremioContainer = styled(Container)`
    gap: 30px;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;

    @media only screen and (max-width: ${breakpoints.md}){
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }
`;

export const ImageContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 80%;
        max-width: 400px;
        border-radius: 10px;
    }

    @media only screen and (max-width: ${breakpoints.md}){
        width: 100%;
    }
`;

export const DetailsContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    text-align: left;
    gap: 30px;

    @media only screen and (max-width: ${breakpoints.md}){
        width: 100%;
        justify-content: center;
        align-items: stretch;
    }

`;

export const Price = styled.span`
    font-weight: bold;
`;

export const Description = styled.p`
    color: ${TextColorDark}!important;
`;

export const QuantityContainer = styled(Container)`
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 0px;

    p {
        margin: 0;
        font-size: 16px;
        color: ${TextColorDark};
    }
`;

export const QuantityControl = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid ${TextColorDark};
    border-radius: 5px;
    padding: 5px 10px;
`;

export const QuantityButton = styled.button`
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: ${TextColorDark};
`;

export const Quantity = styled.span`
    font-size: 18px;
    font-weight: bold;
    color: ${TextColorDark};
`;

export const AddToCartButton = styled.button`
    background-color: ${PrimaryColor};
    color: ${TextColorLight};
    border: none;
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 15px;
    width: 100%;
    cursor: pointer;
`;
