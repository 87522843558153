import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as styles from './styles'; // Importa todos os estilos de styles.js
import GlobalStyle from '../../styles/global';
import Header from '../../components/header';
import { Container, Content, ContentSection } from '../../styles/styles';
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, BackgroundColor, Accent } from '../../styles/global';
import { BackBar, BackButton } from '../../styles/global';

import { SvgUpload } from '../../assets/icons';
import { createPremio } from '../../controllers/premioController';


const CriarPremio = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [value, setValue] = useState('');
    const [estoque, setEstoque] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const handleTitleChange = (e) => setTitle(e.target.value);
    const handleValueChange = (e) => setValue(e.target.value);
    const handleEstoqueChange = (e) => setEstoque(e.target.value);
    const handleDescriptionChange = (e) => setDescription(e.target.value);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        setPreviewImage(URL.createObjectURL(file)); // Mostra a imagem selecionada
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('nome', title);
        formData.append('valor', value);
        formData.append('estoque', estoque);
        formData.append('descricao', description);
        if (image) formData.append('url_imagem', image);

        try {
            await createPremio(formData);
            navigate('/premios');
        } catch (error) {
            setErrorMessage('Erro ao criar o prêmio.');
        }
    };

    return (
        <>
            <Header />
            <GlobalStyle />
            <Container fullWidth direction="column" align="center" justify="center">
                <Container direction="column" width="100%">
                    <BackBar>
                        <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                        <BackButton onClick={() => navigate('/')}>X</BackButton>
                    </BackBar>

                    <ContentSection fullWidth>
                        <Content light>
                            <styles.PremioForm onSubmit={handleSubmit}>
                                <h2>Novo Prêmio</h2>
                                <Container direction="column" width="80%" gap="20px">
                                    <styles.InputField
                                        type="text"
                                        placeholder="Nome do prêmio"
                                        value={title}
                                        onChange={handleTitleChange}
                                        required
                                    />
                                    <styles.RequiredText>Obrigatório *</styles.RequiredText>

                                    <styles.InputField
                                        type="number"
                                        placeholder="Valor do prêmio"
                                        value={value}
                                        onChange={handleValueChange}
                                        required
                                    />
                                    <styles.RequiredText>Obrigatório *</styles.RequiredText>

                                    <styles.InputField
                                        type="number"
                                        placeholder="Estoque disponível"
                                        value={estoque}
                                        onChange={handleEstoqueChange}
                                        required
                                    />
                                    <styles.RequiredText>Obrigatório *</styles.RequiredText>

                                    <styles.TextArea
                                        placeholder="Descrição do prêmio"
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        required
                                    />
                                    <styles.RequiredText>Obrigatório *</styles.RequiredText>

                                    <styles.FileInput>
                                        <div className='interna'>
                                            <div>
                                                <p><strong>Imagem principal</strong></p>
                                            </div>
                                            <div>
                                                <SvgUpload />
                                                <span>Enviar imagem</span>
                                                <input type="file" accept="image/*" onChange={handleImageChange} required />
                                            </div>
                                        </div>
                                        {previewImage && <img src={previewImage} alt="Pré-visualização da imagem" />}
                                    </styles.FileInput>
                                    <styles.RequiredText>Obrigatório *</styles.RequiredText>

                                    {errorMessage && <styles.ErrorMessage>{errorMessage}</styles.ErrorMessage>}

                                    <styles.SubmitButton type="submit">Confirmar</styles.SubmitButton>
                                </Container>
                            </styles.PremioForm>
                        </Content>
                    </ContentSection>
                </Container>
            </Container>
        </>
    );
};

export default CriarPremio;
