import React from 'react';
import Success, { InfoContainer, InfoContainer100, InfoContainer50 } from './success';
import sendCoin from '../assets/icons/coin-sucess.svg';
import coin1 from '../assets/icons/moeda-1.svg';
import coin5 from '../assets/icons/moeda-5.svg';
import coin10 from '../assets/icons/moeda-10.svg';
import coin50 from '../assets/icons/moeda-50.svg';
import coin100 from '../assets/icons/moeda-100.svg';
import { Container } from '../styles/styles';

function Confirmacao({ emocionador, moedas, onClose }) {
  const totalMoedas = Object.keys(moedas).reduce((total, value) => {
    return total + moedas[value] * parseInt(value);
  }, 0);

  const content = (
    <>
      <h3>Enviado com sucesso!</h3>
      <InfoContainer light wrap="wrap" gap="0">
        <InfoContainer100>
          <p><strong>Emocionador</strong></p>
          <p> {emocionador.nome}</p>
        </InfoContainer100>
        <InfoContainer50>
          <p><strong>Unidade</strong></p>
          <p>{emocionador.unidade}</p>
        </InfoContainer50>
        <InfoContainer50>
          <p><strong>Seção</strong></p>
          <p>{emocionador.cargo}</p>
        </InfoContainer50>
        <InfoContainer50>
          <p><strong>Setor</strong></p>
          <p>{emocionador.setor}</p>
        </InfoContainer50>
        <InfoContainer50>
          <p><strong>Saldo</strong></p>
          <p>{totalMoedas}</p>
        </InfoContainer50>
        <InfoContainer100>
          <p><strong>Moedas</strong></p>
          <Container padding="0">
            {moedas['1'] > 0 && <img src={coin1} alt="1 Moeda" className='moeda' />}
            {moedas['5'] > 0 && <img src={coin5} alt="5 Moedas" className='moeda' />}
            {moedas['10'] > 0 && <img src={coin10} alt="10 Moedas" className='moeda' />}
            {moedas['50'] > 0 && <img src={coin50} alt="50 Moedas" className='moeda' />}
            {moedas['100'] > 0 && <img src={coin100} alt="100 Moedas" className='moeda' />}
            <style jsx>{`.moeda{width: 55px!important}`}</style>
          </Container>
        </InfoContainer100>
      </InfoContainer>
      <img src={sendCoin} alt="Moedas enviadas" />
    </>
  );

  return (
    <Success content={content} onClose={onClose} />
  );
}

export default Confirmacao;
