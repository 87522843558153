import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Header from '../../components/header';
import { Container, Content, ContentSection } from '../../styles/styles';
import GlobalStyle, { BackBar, BackButton } from '../../styles/global';
import { SvgUser, SvgCalendar, SvgUpload } from '../../assets/icons';
import { Employee, EmployeeActionItem, ErrorMessage, Aviso, LogoutButton } from './styles';
import { getUserData } from '../../controllers/userController';
import { logout } from '../../auth';

import { FaChevronRight, FaPlus, FaUsers } from 'react-icons/fa';

const UserDetails = () => {


    const user = JSON.parse(localStorage.getItem('user'));
    const id = user.idusuario;
    const navigate = useNavigate();
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');
    const [cargo, setCargo] = useState('');
    const [unidade, setUnidade] = useState('');
    const [secao, setSecao] = useState('');
    const [setor, setSetor] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        if (id) {
            getUserData(id)
                .then(data => {
                    if (!data) {
                        setNotFound(true); // Se o prêmio não for encontrado, atualiza o estado
                    } else {
                        setNome(data.nome);
                        setEmail(data.email);
                        setCpf(data.cpf);
                        setCargo(data.cargo);
                        setUnidade(data.unidade);
                        setSecao(data.secao);
                        setSetor(data.setor);
                    }
                })
                .catch(err => setErrorMessage(err.message))
        }
    }, [id]);

    if (notFound) {
        return (
            <>
                <Header />
                <GlobalStyle />
                <ContentSection fullWidth height="100vh">
                    <Content direction="column" align="center">
                        <Aviso light>
                            <p>Úsuario não encontrado</p>
                            <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                        </Aviso>
                    </Content>
                </ContentSection>
            </>
        );
    }

    return (
        <>
            <Header />
            <GlobalStyle />
            <Container fullWidth direction="column" align="center" justify="center">
                <Container direction="column" width="100%">
                    <BackBar>
                        <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                        <BackButton onClick={() => navigate('/')}>X</BackButton>
                    </BackBar>

                    <ContentSection fullWidth>
                        <Content light>
                            <Container direction="column" gap="40px">

                                <Employee light>
                                    <div className="employee-icon-div">
                                        <SvgUser className="employee-icon" />
                                    </div>
                                    <div className="employee-details">
                                        <h3>{nome}</h3>
                                        <small>{email}</small>
                                        <p>{unidade} - <small>{secao}</small> - <small>{setor}</small></p>

                                    </div>
                                </Employee>

                                <Container direction="column" padding="0">
                                    <Link to={`/perfil/editar/${id}`}>
                                        <EmployeeActionItem>
                                            <Container gap="20px" align="center" padding="0">
                                                <div className="employee-icon-div">
                                                    <SvgUser className="employee-icon" />
                                                </div>
                                                <p>Minha Conta</p>
                                            </Container>
                                            <FaChevronRight />
                                        </EmployeeActionItem>
                                    </Link>

                                    {user.nivel == 1 ? (
                                        <>
                                        <Link to={`/usuarios`}>
                                                <EmployeeActionItem>
                                                    <Container gap="20px" align="center" padding="0">
                                                        <div className="employee-icon-div">
                                                            <FaUsers />
                                                        </div>
                                                        <p>Todos os Usuário</p>
                                                    </Container>
                                                    <FaChevronRight />
                                                </EmployeeActionItem>
                                            </Link>

                                            <Link to={`/perfil/novo`}>
                                                <EmployeeActionItem>
                                                    <Container gap="20px" align="center" padding="0">
                                                        <div className="employee-icon-div">
                                                            <FaPlus />
                                                        </div>
                                                        <p>Novo Usuário</p>
                                                    </Container>
                                                    <FaChevronRight />
                                                </EmployeeActionItem>
                                            </Link>

                                            <Link to={`/premios/premio/novo`}>
                                                <EmployeeActionItem>
                                                    <Container gap="20px" align="center" padding="0">
                                                        <div className="employee-icon-div">
                                                            <FaPlus />
                                                        </div>
                                                        <p>Novo Prêmio</p>
                                                    </Container>
                                                    <FaChevronRight />
                                                </EmployeeActionItem>
                                            </Link>

                                        </>
                                    ) : null}

                                </Container>

                                <LogoutButton onClick={logout}>Sair</LogoutButton>

                                <Container direction="column" width="80%" gap="20px">


                                </Container>

                                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

                            </Container>
                        </Content>
                    </ContentSection>
                </Container>
            </Container >
        </>
    );
};

export default UserDetails;
