import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container } from '../styles/styles';
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, Accent } from '../styles/global';
import { BackBar, BackButton, IconInput } from '../styles/global';
import { ContentSection, Content } from '../styles/styles';
import { SvgUser, SvgSearch } from '../assets/icons';
import { getEmocionadoresRecentes, getEmocionadores } from '../controllers/emocionarController';

const SearchInput = styled.input`
  width: 100%;
  max-width: 600px;
  padding: 10px;
  border-radius: 20px;
  border: none;
  margin-bottom: 20px;
  background: ${Accent};
`;

const EmployeeAll = styled(Container)`
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    background-color: ${SecondaryColor};
    border-radius: 50px 50px 0 0;
    h3{color: ${props => props.light ? TextColorLight : TextColor};}
`;

const EmployeeList = styled(Container)`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  gap: 10px;

  h5{color: ${props => props.light ? TextColorLight : TextColor };}

  >*:hover{
    background-color: ${PrimaryColor};
    color: ${TextColorLight};
    cursor: pointer;
    border-radius: 20px;
  }
  >*:hover .employee-icon-div{
    background-color: ${TextColorLight};
  }
  >*:hover .employee-icon{
    color: ${PrimaryColor};
  }
  >*:hover .employee-name h5, >*:hover .employee-details p, >*:hover .employee-details small{color: ${props => props.light ? "inherit" : TextColorLight};}
`;

const EmployeeItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

const EmployeeInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h4{width: fit-content;}
  p{width: fit-content;}
  small{width: fit-content;}

  .employee-icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: ${props => props.light ? TextColorLight : PrimaryColor};
    text-align: center;
    padding: 10px;
  }
  .employee-icon {
    font-size: 10px;
    color: ${props => props.light ? SecondaryColor : TextColorLight};
  }

  .employee-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: ${props => props.light ? TextColorLight : TextColor};
    flex: 1;
    text-align: left;
  }

  .employee-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    flex: 0.5;
    text-align: right;
    p, small{font-size: 12px;color: ${props => props.light ? TextColorLight : TextColor};}

    p{font-weight: 500;}
    small{font-weight: lighter;}
  }
`;

function SelecionarEmocionador({ onSelect, onClose }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [emocionadoresRecentes, setEmocionadoresRecentes] = useState([]);
  const [emocionadores, setEmocionadores] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const usuarioId = JSON.parse(localStorage.getItem('user')).idusuario; // ID do usuário logado

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Buscar emocionadores recentes
        const recentData = await getEmocionadoresRecentes(usuarioId);
        setEmocionadoresRecentes(recentData);

        // Buscar todos os emocionadores
        const allData = await getEmocionadores(usuarioId);
        setEmocionadores(allData);
      } catch (error) {
        setErrorMessage('Erro ao carregar emocionadores.');
      }
    };

    fetchData();
  }, [usuarioId]);


  const filteredEmocionadores = emocionadores.filter(emocionador =>
    emocionador.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container direction="column" width="100%">
      <BackBar>
        <BackButton href="/">← Voltar</BackButton>
        <BackButton onClick={onClose}>X</BackButton>
      </BackBar>
      <ContentSection fullWidth>
        <Content>
          <IconInput>
            <SvgSearch />
            <input
              placeholder="Pesquisar Emocionadores"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </IconInput>

          {emocionadoresRecentes.length > 0 && (
            <>
              <h2>Emocionadores Recentes</h2>
              <EmployeeList>
                {emocionadoresRecentes.map(emocionador => (
                  <EmployeeItem key={emocionador.idusuario} onClick={() => onSelect(emocionador)}>
                    <EmployeeInfo>
                      <div className='employee-name'>
                        <div className="employee-icon-div">
                          <SvgUser className="employee-icon" />
                        </div>
                        <h5>{emocionador.nome}</h5>
                      </div>
                      <div className="employee-details">
                        <p>{emocionador.unidade}</p>
                        <small>{emocionador.setor}</small>
                      </div>
                    </EmployeeInfo>
                  </EmployeeItem>
                ))}
              </EmployeeList>
            </>
          )}

          <EmployeeAll light>
            <h3>Todos os Emocionadores</h3>
            <EmployeeList light>
              {filteredEmocionadores.map(emocionador => (
                <EmployeeItem key={emocionador.idusuario} onClick={() => onSelect(emocionador)}>
                  <EmployeeInfo light>
                    <div className='employee-name'>
                      <div className="employee-icon-div">
                        <SvgUser className="employee-icon" />
                      </div>
                      <h5>{emocionador.nome}</h5>
                    </div>
                    <div className="employee-details">
                      <p>{emocionador.unidade}</p>
                      <small>{emocionador.setor}</small>
                    </div>
                  </EmployeeInfo>
                </EmployeeItem>
              ))}
            </EmployeeList>
          </EmployeeAll>

          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </Content>
      </ContentSection>
    </Container>
  );
}

export default SelecionarEmocionador;
