// auth.js - Arquivo de utilitários para autenticação

export const isAuthenticated = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
};

export const getUserLevel = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user ? user.nivel : null;
};

export const logout = () => {
    localStorage.removeItem('user');
    window.location.href = '/login';
};
