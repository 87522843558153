import React, { useEffect, useState } from 'react';
import GlobalStyle from "../styles/global";
import Header from "../components/header";
import {
  Container,
  UserSection,
  UserInfo,
  BalanceSection,
  BalanceItem,
  ContentSection,
  Content,
  MainMenuSection,
  MainMenuIcon,
  HistorySection,
  HistoryItem
} from "../styles/styles";

import { Link } from 'react-router-dom';
import { getUserHistory, getUserData } from '../controllers/userController';
import { formatDate, formatTime, sumMoedas } from '../utils'; // Importando as funções utilitárias

import logo from '../assets/icons/logo-banco-da-felicidade.svg';
import iconRanking from '../assets/icons/icon-ranking.svg';
import iconHistory from '../assets/icons/icon-history.svg';
import iconPrizes from '../assets/icons/icon-prizes.svg';
import giftIcon from '../assets/icons/icon-gift.png';

import coin1 from '../assets/icons/moeda-1.svg';
import coin5 from '../assets/icons/moeda-5.svg';
import coin10 from '../assets/icons/moeda-10.svg';
import coin50 from '../assets/icons/moeda-50.svg';
import coin100 from '../assets/icons/moeda-100.svg';

import { FaCoins } from "react-icons/fa";

function Home() {
  const [userData, setUserData] = useState({});
  const [MoedasDistribuidas, setMoedasDistribuidas] = useState({});
  const [history, setHistory] = useState([]);
  const [error, setError] = useState('');

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfo = await getUserData(user.idusuario);
        setUserData(userInfo);
        setMoedasDistribuidas(JSON.parse(userInfo.moedas_a_distribuir));

        const userHistory = await getUserHistory(user.idusuario);
        setHistory(userHistory);

      } catch (error) {
        setError('Erro ao carregar dados.');
      }
    };

    fetchData();
  }, [user.idusuario]);

  if (error) {
    return (
      <>
        <Header />
        <GlobalStyle />
        <ContentSection fullWidth height="100vh">
          <Content direction="column" align="center">
            {error}
          </Content>
        </ContentSection>
      </>
    )
  }

  return (
    <>
      <Header />
      <GlobalStyle />

      <UserSection fullWidth direction="row" justify="center" align="center">
        <Container justify="space-around" width="100%">
          <Container light direction="column" gap="50px" align="start" justify="space-between">
            <h1>Bem vindo de volta!</h1>
            <UserInfo light>
              <h2>{userData.nome}</h2>
              <p>{userData.unidade}</p>
              <p>{userData.cargo}</p>
            </UserInfo>
            <BalanceSection light>
              <BalanceItem light>
                <h4>Meu Saldo</h4>
                <h1>{userData.saldo_moedas}</h1>
              </BalanceItem>

              {user.nivel != 0 ? (
                <>
                  <BalanceItem light>
                    <h4>Moedas a distribuir</h4>

                    <h1 className='balanceCoin'>
                      {user.nivel === 1 ? "Ilimitado" :
                          <>  
                          {MoedasDistribuidas["1"] > 0 ? <> <img src={coin1} alt="1 Moeda"/> {MoedasDistribuidas["1"]} </> : null}
                          {MoedasDistribuidas["5"] > 0 ? <> <img src={coin5} alt="5 Moedas"/> {MoedasDistribuidas["5"]} </> : null}
                          {MoedasDistribuidas["10"] > 0 ? <> <img src={coin10} alt="10 Moedas"/> {MoedasDistribuidas["10"]} </> : null}
                          {MoedasDistribuidas["50"] > 0 ? <> <img src={coin50} alt="50 Moedas"/> {MoedasDistribuidas["50"]} </>: null}
                          {MoedasDistribuidas["100"] > 0 ? <> <img src={coin100} alt="100 Moedas"/> {MoedasDistribuidas["100"]} </> : null}
                        </>
                      }
                    </h1>
                  </BalanceItem>
                </>
              ) : null}

            </BalanceSection>
          </Container>
          <Container direction="column" align="center" justify="center">
            <img src={logo} alt="Moeda Banco da Felicidade" />
          </Container>
        </Container>
      </UserSection>

      <ContentSection fullWidth>
        <Content>
          <MainMenuSection>
            {user.nivel == 1 ? (
              <>
                <Container justify="center" align="center" direction="column">
                  <img src={iconRanking} alt="Ranking" />
                  <small>Dashboard</small>
                </Container>
              </>
            ) : null}

            {/* <Container align="center" direction="column">
              <img src={iconHistory} alt="Histórico" />
              <small>Histórico</small>
            </Container> */}

            <Container justify="center" align="center" direction="column">
              <Link to="/premios">
                <img src={iconPrizes} alt="Prêmios" />
                <small>Prêmios</small>
              </Link>
            </Container>

            {user.nivel != 0 && user.nivel != 1 ? (
              <>
                <Container justify="center" align="center" direction="column">
                  <Link to="/emocionar">
                    <MainMenuIcon>
                      <FaCoins />
                    </MainMenuIcon>
                    <small>Emocionar</small>
                  </Link>
                </Container>
              </>
            ) : null}

            {user.nivel == 1 ? (
              <>
                <Container justify="center" align="center" direction="column">
                  <Link to="/distribuir-moedas">
                    <MainMenuIcon>
                      <FaCoins />
                    </MainMenuIcon>
                    <small>Distribuir</small>
                  </Link>
                </Container>
              </>
            ) : null}

          </MainMenuSection>

          <HistorySection>
            <Container justify="space-between" align="center" padding="0">
              <h2>Meu Histórico</h2>
              {user.nivel != 0 ? (
                <>
                  <button>ver todos {'>'}</button>
                </>
              ) : null}
            </Container>
            {history.map((item, index) => (
              <HistoryItem key={index} amountColor={item.type === 'resgate' ? "red" : "green"}>
                <div className="item-type">
                  {item.type === 'resgate' ? <img src={giftIcon} alt={item.type} /> : <img src={logo} alt={item.type} />}
                  <h5>{item.nome} {item.type === 'resgate' ? ` x ${item.qtd}` : ''}</h5>
                </div>
                <div className="item-time-date">
                  <small>{formatTime(item.date)}</small>
                  <small>{formatDate(item.date)}</small>
                </div>
                <p className="item-amount">{item.type === 'credito' ? `+${sumMoedas(item.amount)}` : `-${item.amount}`}</p>
              </HistoryItem>
            ))}
          </HistorySection>
        </Content>
      </ContentSection>
    </>
  );
}

export default Home;
