import React, { useState, useEffect } from 'react';
import GlobalStyle from "../styles/global";
import Header from "../components/header";
import styled from 'styled-components';
import { IconInput } from '../styles/global';
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, TextColorDark, BackgroundColor, BackgroundColorLight, Border } from "../styles/global";
import logo from '../assets/icons/logo-banco-da-felicidade.svg';
import { SvgKey, SvgUser } from '../assets/icons';
import { Container } from '../styles/styles';
import { loginUser } from '../controllers/userController'; // Importe o controlador
import Inputmask from 'inputmask';

const LoginSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const LoginContainer = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  color: ${TextColor};
  gap: 10px;

  h2 { margin-bottom: 20px; }

  img {
    margin-bottom: 20px;
    width: 150px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;

    input {
      padding: 5px 10px;
      border: 1px solid ${Border};
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      width: 100%;
    }

    button {
      padding: 5px 80px;
      background-color: ${PrimaryColor};
      color: ${TextColorLight};
      border: none;
      border-radius: 50px;
      cursor: pointer;
      font-size: 16px;
    }

    button:hover {
      background-color: ${PrimaryColor};
      opacity: 0.9;
    }
  }
`;

const FormContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 50px;
  margin-top: -40px;

  a {
    color: ${TextColorDark};
    text-decoration: underline;
    font-size: 14px;
    align-self: flex-end;
    text-align: right;
  }

  a:hover {
    text-decoration: underline;
  }
`;

function Login() {
  const [cpf, setCpf] = useState('');
  const [senha, setSenha] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    Inputmask("999.999.999-99").mask(document.getElementById("cpf"));
  }, []);

  const removeMaskCPF = (cpf) => {
    return cpf.replace(/\D/g, ''); // Remove tudo que não é número
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await loginUser(removeMaskCPF(cpf), senha); // Utilize o controlador aqui
      // Armazenar o usuário no localStorage ou cookies
      localStorage.setItem('user', JSON.stringify(response.usuario));
      setErrorMessage('');
      // Redirecionar para a página principal ou painel
      window.location.href = '/';
    } catch (error) {
      setErrorMessage(error.mensagem);
    }
  };

  return (
    <>
      {/* <Header /> */}
      <GlobalStyle />
      <LoginSection>
        <LoginContainer>
          <img src={logo} alt="Logo Banco da Felicidade" />
          <h2>Olá, consulte o seu porquinho!</h2>
          <form onSubmit={handleSubmit}>
            <IconInput>
              <SvgUser />
              <input
                type="text"
                id="cpf"
                name="cpf"
                placeholder="CPF"
                value={cpf}
                autoComplete='off'
                onChange={(e) => setCpf(e.target.value)}
                required
              />
            </IconInput>
            <IconInput>
              <SvgKey />
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                required
              />
            </IconInput>
            <FormContainer direction="column">
              <a href="/recuperar-senha">Esqueci minha senha</a>
              <button type="submit">Entrar</button>
            </FormContainer>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          </form>
        </LoginContainer>
      </LoginSection>
    </>
  );
}

export default Login;
