import React from 'react';
import Success, { InfoContainer, InfoContainer100, InfoContainer50 } from './success';
import sendCoin from '../assets/icons/coin-sucess.svg';

function ConfirmacaoADM({ emocionador, moedas, onClose }) {
  const totalMoedas = Object.keys(moedas).reduce((total, value) => {
    return total + moedas[value] * parseInt(value);
  }, 0);

  const content = (
    <>
      <h3>Enviado com sucesso!</h3>
      <InfoContainer light wrap="wrap" gap="0">
        <InfoContainer100>
          <p><strong>Emocionador</strong></p><p> {emocionador.nome}</p>
        </InfoContainer100>
        <InfoContainer50>
          <p><strong>Unidade</strong></p><p>{emocionador.unidade}</p>
        </InfoContainer50>
        <InfoContainer50>
          <p><strong>Seção</strong></p><p>{emocionador.cargo}</p>
        </InfoContainer50>
        <InfoContainer50>
          <p><strong>Setor</strong></p><p>{emocionador.setor}</p>
        </InfoContainer50>
        <InfoContainer50>
          <p><strong>Saldo</strong></p><p>{totalMoedas}</p>
        </InfoContainer50>
        <InfoContainer100>
          <p><strong>Remetente</strong></p><p>{emocionador.nome}</p>
        </InfoContainer100>
      </InfoContainer>
      <img src={sendCoin} alt="Moedas enviadas" />
    </>
  );

  return (
    <Success content={content} onClose={onClose} />
  );
}

export default ConfirmacaoADM;
